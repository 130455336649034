import React, { useEffect } from "react";
import { graphql } from "gatsby";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line
import { PageMeta } from "@components/pageMeta";
import Layout from "@components/layout";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { useTracking } from "@lib/tracking";

const SimplePage = ({ data: { page } }) => {
  const { config, seo, pageBody, title } = page || {};
  const { track } = useTracking();

  useEffect(() => {
    track("Viewed Page", {
      page: title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout {...config} pageBg={{ hex: "#F1EFEB" }} hideSub>
      <PageMeta {...seo} />
      <div className="px-gutter py-32">
        <h1 className="blockH0 font-medium text-center pb-20">{title}</h1>
        <PortableTextBlock text={pageBody} className="max-w-7xl mx-auto" />
      </div>
    </Layout>
  );
};

export default SimplePage;

export const pageQuery = graphql`
  query simplePageQuery($slug: String!) {
    page: sanitySimplePage(slug: { current: { eq: $slug } }) {
      title
      seo {
        ...SEO
      }
      title
      pageBody: _rawPageBody(resolveReferences: { maxDepth: 10 })
      config {
        ...PageSettings
      }
    }
  }
`;
